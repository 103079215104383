let selectedVehicle = 0;
let selectedModelVehicle = {};
let lastSelectedVehicleType = 0;

$('#vehicles-submenu .input-icon').on('click', function () {
    let typeVehicle = $(this).val();

    selectedVehicle = typeVehicle;
    if (typeVehicle === 'NV') {
        // Select vehicle type element
        $('#vehicles-submenu .input-icon').closest('.range-group').removeClass('added');
        $(this).closest('.range-group').addClass('added');
        $('.vehicle-selection .range-group .vehicles').text("");
        $('form[name="searchForm"] .num_vehicles').val($(this).data('name'));
        return true;
    } else {
        lastSelectedVehicleType = typeVehicle;
        if ((typeVehicle === 'C' || typeVehicle === 'V')) {
            $('.vehicle-data-container .car-vehicle-selection').show();
            $('.vehicle-data-container .other-vehicle-selection').hide();

        } else {
            $('.vehicle-data-container .car-vehicle-selection').hide();
            $('.vehicle-data-container .other-vehicle-selection').show();
        }
        $('.vehicle-wrapper').css("left", "-100%");
    }

    return false;
});

$('.cancel-vehicle-model').on('click', function () {
    $('.vehicle-wrapper').css("left", "0");
});

$('.add-vehicle-model').on('click', function () {
    let vehicleModel = {};
    if ((lastSelectedVehicleType === 'C' || lastSelectedVehicleType === 'V')) {
        vehicleModel.model = $('select[name="vmodel"]').val();
        vehicleModel.brand = $('select[name="vbrand"]').val();
        vehicleModel.type = lastSelectedVehicleType;
        vehicleModel.name = $('select[name="vbrand"] :selected').text() + " - " + $('select[name="vmodel"] :selected').text();
        vehicleModel.height = $('select[name="vehicle-height"]').val();
        vehicleModel.hasTrailer = $('input[name="hasTrailer"]').val();
        vehicleModel.trailerSize = $('select[name="trailer-size"]').val();
        vehicleModel.vehicleQty = 1;
    }
    else {
        vehicleModel.vehicleQty = $('select[name="vehicle_qty"]').val();
        vehicleModel.name = "Qty: " + vehicleModel.vehicleQty;
    }

    selectedModelVehicle = vehicleModel;

    // Select vehicle type element
    $('#vehicles-submenu .input-icon').closest('.range-group').removeClass('added');
    $('.vehicle-selection .range-group[data-type="' + lastSelectedVehicleType + '"]').addClass('added');
    $('.vehicle-selection .range-group .vehicles').text("");
    $('.vehicle-selection .range-group[data-type="' + lastSelectedVehicleType + '"] .input-range-group-step .input-icon').prop('checked', true);

    let vehicleCatName = $('.vehicle-selection .range-group[data-type="' + lastSelectedVehicleType + '"] .input-icon').data('name');
    $('form[name="searchForm"] .num_vehicles').val(vehicleCatName + " (x" + vehicleModel.vehicleQty + ")");
    $('.vehicle-selection .range-group[data-type="' + lastSelectedVehicleType + '"] .vehicles').text(selectedModelVehicle.name);
    $('.vehicle-wrapper').css("left", "0");
});

$('input[name="hasTrailer"]').on('click', function () {
    if ($(this).is(':checked')) {
        $('.trailer-selection').show();
    } else {
        $('.trailer-selection').hide();
    }
});
